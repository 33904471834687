import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  className?: string
  children: React.ReactNode
  duration?: number
  yAxisDistance?: string
}

export const FadeInOutAnimation = memo(function FadeInOutAnimation({
  className,
  children,
  duration = 600,
  yAxisDistance = '3.75rem',
}: Props) {
  return (
    <Container className={className}>
      <Fade bottom distance={yAxisDistance} duration={duration}>
        {children}
      </Fade>
    </Container>
  )
})

const Container = styled.div``
