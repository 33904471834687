import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import React, { memo } from 'react'

export interface Props {
  description?: string
  label?: string
  logo?: { [key: string]: any }
  title?: string
  variant?: Variant
}

export const Intro = memo(function Intro({
  description,
  label,
  logo,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container variant={variant}>
      <Wrapper>
        {logo ? (
          <FadeInOutAnimation>
            <Logo
              src={logo.src}
              alt={logo.alt}
              width={logo.width}
              height={logo.height}
            />
          </FadeInOutAnimation>
        ) : null}
        {label ? (
          <FadeInOutAnimation>
            <Label>{label}</Label>
          </FadeInOutAnimation>
        ) : null}
        {title ? (
          <FadeInOutAnimation>
            <Title variant={variant}>{title}</Title>
          </FadeInOutAnimation>
        ) : null}
        {description ? (
          <FadeInOutAnimation>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInOutAnimation>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 7.5rem auto 9.75rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 5.25rem auto 7.5rem;
  }

  @media (max-width: 767px) {
    margin: 5.25rem auto;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          background: ${theme.colors.variants.neutralLight3};
          margin: 0;
          padding: 5.25rem 0;

          @media (max-width: 1199px) {
            margin: 0;
          }
        `
    }
  }}
`

const Wrapper = styled.div`
  max-width: 45rem;
  margin: auto;
  padding: 0 1.5rem;
`

const Logo = styled.img`
  margin: 0 auto 1.5625rem;
  opacity: 0.2;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.9375rem;
  letter-spacing: 0.0938rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
  transition: 0.3s ease-out;
`

const Title = styled.h2<ContainerProps>`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin: 0 auto 1.5rem;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          font-size: 1.5rem;
          line-height: 2rem;
        `
    }
  }}
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
